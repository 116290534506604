import React, {useState, useEffect} from "react";
import { useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import { selectAllRoutes, selectTomorrowAllRoutes, selectAllActiveDrivers, selectAllActiveVehicles, selectHistoryRoutes } from "../../store";
import { CustomerService, SignatureRequestService, EventsService } from "../../services";
import moment from 'moment';
import { CUSTOMER_TYPE, CUSTOMER_TYPE_TEXT, PERSONAL_ROUTE_STATUS } from "../../shared";

const RouteReportWithSignature = () => {
  const params = useParams();
  const allRoutes = useSelector(selectAllRoutes);
  const tomorrowRoutes = useSelector(selectTomorrowAllRoutes);
  const historyRoutes = useSelector(selectHistoryRoutes);
  const drivers = useSelector(selectAllActiveDrivers);
  const vehicles = useSelector(selectAllActiveVehicles);
  const currentRoute = (allRoutes.find(item => item.id === params.id)) || (tomorrowRoutes.find(item => item.id === params.id)) || (historyRoutes.find(item => item.id === params.id));
  const routeIndex = currentRoute?.type === 'inbound' ? 
    ((allRoutes.map((r) => Object.assign({}, r, {vehicleNumber: vehicles?.find((v) => r.vehicle === v.id)?.vehicle_number || 0})).sort((a, b) => a?.vehicleNumber - b?.vehicleNumber).filter(item => item.type === 'inbound').findIndex(item => item.id === params.id))) + 1 || ((tomorrowRoutes.filter(item => item.type === 'inbound').findIndex(item => item.id === params.id)))+1 || ((historyRoutes.filter(item => item.type === 'inbound').findIndex(item => item.id === params.id)))+1 :
    ((allRoutes.map((r) => Object.assign({}, r, {vehicleNumber: vehicles?.find((v) => r.vehicle === v.id)?.vehicle_number || 0})).sort((a, b) => a?.vehicleNumber - b?.vehicleNumber).filter(item => item.type === 'outbound').findIndex(item => item.id === params.id)))+1 || ((tomorrowRoutes.filter(item => item.type === 'outbound').findIndex(item => item.id === params.id))) +1 || ((historyRoutes.filter(item => item.type === 'outbound').findIndex(item => item.id === params.id)))+1;
  const currentVehicle = vehicles.find(item => item.id === currentRoute?.vehicle );
  const currentDriver = drivers.find(item => item.id === currentRoute?.driver);
  
  const [signature, setSignature] = useState(undefined);

  const [directorSignature, setDirectorSignature] = useState(undefined);

  const site = EventsService.site;

  const navigate = useNavigate();
  
  const getRelatedInboundOutboundRoutesForThisView = (routeType) => {
    if (allRoutes.find(item => item.id === params.id)) {
      return allRoutes.filter(item => item.type!== routeType);
    }
    if (tomorrowRoutes.find(item => item.id === params.id)) {
      return tomorrowRoutes.filter(item => item.type!==routeType);
    }
    if (historyRoutes.find(item => item.id === params.id)) {
      return historyRoutes.filter(item => item.type!==routeType);
    }
  }

  const getInboundOrOutboundRouteCustomer = (customer_id) => {
    return getRelatedInboundOutboundRoutesForThisView(currentRoute?.type)?.
      find((route) => route?.name?.toLowerCase() === currentRoute?.name?.toLowerCase() && route?.schedule_date == currentRoute?.schedule_date)?.route_customer_list?.
      find((cust) => cust?.customer_id === customer_id)
  }

  const getOtherRouteWithThisCustomer = (customer_id) => {
    return getRelatedInboundOutboundRoutesForThisView(currentRoute?.type)?.find((route) => route?.name !== currentRoute?.name && route?.schedule_date == currentRoute?.schedule_date && route?.route_customer_list?.find(cust => cust?.customer_id === customer_id));
  }
  
  const directToView = () => {
    navigate(`/trans-routes/${params.id}`)
  }
  
  useEffect(() => {
    const dateArr = moment(currentRoute?.schedule_date)?.format('MM/DD/YYYY')?.split('/') || [];
       
    CustomerService.getAvatar(`${currentRoute?.id}_${currentRoute?.driver}_${dateArr[0]}_${dateArr[1]}`).then(data => {
      setSignature(data.data);
    });
    CustomerService.getAvatar(`center_director_signature_site_${site}`).then(data => {
      if (data?.data) {
        setDirectorSignature(data?.data)
      }
    });
  }, [currentRoute]);
  return (
    <>
      <div className="list row noprint">
        <div className="col-md-12 text-primary mb-2">
          <button className="btn btn-link btn-sm" onClick={() => directToView()}>Back</button>
        </div>
      </div>
      <div>
        <div className="float-end" style={{'background': '#eee', padding: '12px 8px', width: '50px', height: '50px', textAlign: 'center'}}>
          <span>{routeIndex}</span>
        </div>
      </div>
      <div className="list row mb-4">
        <div className="col-md-3 col-sm-6 col-xs-12 mb-4">
          Route (路线): <span className="report-field-value"><strong>{currentRoute?.name}</strong></span>
        </div>
        <div className="col-md-3 col-sm-6 col-xs-12 mb-4">
          Driver (司机): <span className="report-field-value"><strong>{currentDriver?.name}</strong></span>
        </div>
        <div className="col-md-3 col-sm-6 col-xs-12 mb-4">
          Vehicle (车号): <span className="report-field-value"><strong>{currentVehicle?.vehicle_number}</strong></span>
        </div>
        <div className="col-md-3 col-sm-6 col-xs-12 mb-4">
          Date (日期): <span className="report-field-value"><strong>{currentRoute?.start_time && (new Date(currentRoute?.start_time))?.toLocaleDateString()}</strong></span>
        </div>
        
      </div>
      <div className="list row">
        <div className="col-md-6 col-sm-12 mb-4">
          Driver's Signature (司机签字): {signature && <span className="mb-2">{signature && <img width="100px" src={`data:image/jpg;base64, ${signature}`}/>}</span>}
        </div>
        <div className="col-md-6 col-sm-12 mb-4">
          {/* Manager's Signature (经理签字): {directorSignature && <span className="mb-2">{directorSignature && <img width="100px" src={`data:image/jpg;base64, ${directorSignature}`}/>}</span>} */}
          Manager's Signature (经理签字): <img width="100px" src="/images/signature.jpeg"/>
        </div>
      </div>
      <div className="list row">
        <div className="col-md-12 mb-4">
          <table className="personnel-info-table">
            <thead>
              <tr>
                <th>No.</th>
                <th>Name</th>
                <th>Phone</th>
                <th>Address</th>
                <th>Room</th>
                <th colSpan={2}>Pick-Up</th>
                <th>Pick-Up</th>
                <th>Arrival</th>
                <th>Departure</th>
                <th>Drop-Off</th>
                <th>RestStop</th>
                <th>Notice</th>
              </tr>
              <tr>
                <th>序号</th>
                <th>姓名</th>
                <th>联系电话</th>
                <th>地址</th>
                <th>房间号</th>
                <th colSpan={2}>出勤</th>
                <th>接到时间</th>
                <th>抵达中心</th>
                <th>离开中心</th>
                <th>送达时间</th>
                <th></th>
                <th>备注</th>
              </tr>
              <tr>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th>Y</th>
                <th>N</th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {
                currentRoute?.route_customer_list?.map((customer, index) => {
                  const relativeRouteCustomer = getInboundOrOutboundRouteCustomer(customer?.customer_id);
                  // console.log('cust', relativeRouteCustomer);
                  const otherRouteWithThisCustomer = getOtherRouteWithThisCustomer(customer?.customer_id);
                  // console.log('other', otherRouteWithThisCustomer) 
                  const customerInOtherRoute = otherRouteWithThisCustomer?.route_customer_list?.find(cust => cust?.customer_id === customer?.customer_id);
                  // console.log('other user', customerInOtherRoute)
                  return (<tr>
                    <td>{index+1}</td>
                    <td>{customer?.customer_name}</td>
                    <td>{customer?.customer_phone}</td>
                    <td>{customer?.customer_address_override || customer?.customer_address}</td>
                    <td></td>
                    <td>{ ![PERSONAL_ROUTE_STATUS.SCHEDULED_ABSENT, PERSONAL_ROUTE_STATUS.UNEXPECTED_ABSENT].includes(relativeRouteCustomer?.customer_route_status) && ![PERSONAL_ROUTE_STATUS.SCHEDULED_ABSENT, PERSONAL_ROUTE_STATUS.UNEXPECTED_ABSENT].includes(customer?.customer_route_status) ? "✓" : ''}</td>
                    <td>{![PERSONAL_ROUTE_STATUS.SCHEDULED_ABSENT, PERSONAL_ROUTE_STATUS.UNEXPECTED_ABSENT].includes(relativeRouteCustomer?.customer_route_status) && ![PERSONAL_ROUTE_STATUS.SCHEDULED_ABSENT, PERSONAL_ROUTE_STATUS.UNEXPECTED_ABSENT].includes(customer?.customer_route_status) ? "" : 'x'}</td>
                    <td>{customer?.customer_pickup_time ? new Date(customer?.customer_pickup_time).toLocaleTimeString('en-US', {hour12: false, hour: '2-digit', minute: '2-digit'}) : (relativeRouteCustomer?.customer_pickup_time? new Date(relativeRouteCustomer?.customer_pickup_time).toLocaleTimeString('en-US', {hour12: false, hour: '2-digit', minute: '2-digit'}) : (customerInOtherRoute?.customer_pickup_time ? new Date(customerInOtherRoute?.customer_pickup_time)?.toLocaleTimeString('en-US', {hour12: false, hour: '2-digit', minute: '2-digit'}): ''))}</td>
                    <td>{customer?.customer_enter_center_time ? new Date(customer?.customer_enter_center_time).toLocaleTimeString('en-US', {hour12: false, hour: '2-digit', minute: '2-digit'}) : (relativeRouteCustomer?.customer_enter_center_time ? new Date(relativeRouteCustomer?.customer_enter_center_time).toLocaleTimeString('en-US', {hour12: false, hour: '2-digit', minute: '2-digit'}) : (customerInOtherRoute?.customer_enter_center_time ? new Date(customerInOtherRoute?.customer_enter_center_time)?.toLocaleTimeString('en-US', {hour12: false, hour: '2-digit', minute: '2-digit'}): ''))}</td>
                    <td>{customer?.customer_leave_center_time && customer?.customer_route_status !== PERSONAL_ROUTE_STATUS.SKIP_DROPOFF ? new Date(customer?.customer_leave_center_time).toLocaleTimeString('en-US', {hour12: false, hour: '2-digit', minute: '2-digit'}) : (relativeRouteCustomer?.customer_leave_center_time && relativeRouteCustomer?.customer_route_status !== PERSONAL_ROUTE_STATUS.SKIP_DROPOFF ? new Date(relativeRouteCustomer?.customer_leave_center_time).toLocaleTimeString('en-US', {hour12: false, hour: '2-digit', minute: '2-digit'}) : '')}</td>
                    <td>{customer?.customer_dropoff_time && customer?.customer_route_status !== PERSONAL_ROUTE_STATUS.SKIP_DROPOFF ? new Date(customer?.customer_dropoff_time).toLocaleTimeString('en-US', {hour12: false, hour: '2-digit', minute: '2-digit'}) : (relativeRouteCustomer?.customer_dropoff_time && relativeRouteCustomer?.customer_route_status !== PERSONAL_ROUTE_STATUS.SKIP_DROPOFF ? new Date(relativeRouteCustomer?.customer_dropoff_time).toLocaleTimeString('en-US', {hour12: false, hour: '2-digit', minute: '2-digit'}) : '')}</td>
                    <td></td>
                    <td>
                      {customer?.customer_type !== CUSTOMER_TYPE.MEMBER && <div>{ CUSTOMER_TYPE_TEXT[customer?.customer_type]}</div> }
                      { !relativeRouteCustomer && otherRouteWithThisCustomer && (
                          <div>
                            {`${currentRoute?.type === 'inbound' ? 'Switch to Route ' : 'Switch from Route '} ${otherRouteWithThisCustomer?.name}`}
                          </div>
                        )
                      }
                    </td>
                  </tr>)
                })
              }
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default RouteReportWithSignature;